import React from 'react';
import styled from 'styled-components';
import store from '../redux/store';
import { TOGGLE_TEMPORARY_TUTORIAL_ACTIVE } from '../redux/redux-constants';
import { Button } from 'reactstrap';
import useConfig from '../hooks/use-config';

export default function TemporaryTutorial({ link }) {
  const { tFallback } = useConfig();
  const handleToggleTutorial = () => {
    store.dispatch({
      type: TOGGLE_TEMPORARY_TUTORIAL_ACTIVE,
    });
  };

  return (
    <TutorialOverlay>
      <TutorialHeader>
        <h3 className="mb-0 text-white">
          {tFallback(
            'LABEL_MANAGEMENT_CHALLENGE_TUTORIAL',
            'Management Challenge Tutorial'
          )}
        </h3>
        <Button outline color="secondary" onClick={handleToggleTutorial}>
          {tFallback('LABEL_EXIT_TUTORIAL', 'Exit Tutorial')}
        </Button>
      </TutorialHeader>
      <TutorialIFrame src={link} frameBorder="0" />
    </TutorialOverlay>
  );
}

const TutorialOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
`;

const TutorialHeader = styled.div`
  height: 63px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  background: #000;
`;

const TutorialIFrame = styled.iframe`
  height: calc(100vh - 63px);
  width: 100%;
`;
