import _ from 'lodash';
import * as types from '../../redux-constants';

const initialState = {
  trainingStatus: null, // { type, employeeId, points }
  tutorialStepIndex: null,
  tutorialSteps: getTutorialSteps(),
  isTutorialReady: false,
  isTeammateView: false,
  isTemporaryTutorialActive: false
};

// TODO: rename to localStateReducer and use for all local-only player state changes?
export default function playerReducer(state = initialState, action) {
  switch (action.type) {
    case types.START_TRAINING_EMPLOYEE:
      return {
        ...state,
        trainingStatus: {
          type: action.payload.type,
          employeeId: action.payload.employeeId,
          points: null,
        },
      };

    case types.SET_TRAINING_RESULT_POINTS: {
      return {
        ...state,
        trainingStatus: _.assign({}, state.trainingStatus, {
          points: action.payload,
        }),
      };
    }

    case types.END_TRAINING_EMPLOYEE:
      return {
        ...state,
        trainingStatus: null,
      };

    case types.LAUNCH_TUTORIAL:
      return {
        ...state,
        tutorialStepIndex: 0,
      };

    case types.SET_TUTORIAL_READY:
      return {
        ...state,
        isTutorialReady: true,
      };

    case types.END_TUTORIAL:
      return {
        ...state,
        tutorialStepIndex: null,
        isTutorialReady: false,
      };

    case types.PREVIOUS_TUTORIAL_STEP: {
      return {
        ...state,
        tutorialStepIndex: Math.max(state.tutorialStepIndex - 1, 0),
      };
    }

    case types.NEXT_TUTORIAL_STEP: {
      return {
        ...state,
        tutorialStepIndex: Math.min(
          state.tutorialStepIndex + 1,
          state.tutorialSteps.length - 1
        ),
      };
    }

    case types.SET_TEAMMATE_VIEW: {
      return {
        ...state,
        isTeammateView: action.payload,
      };
    }

    case types.TOGGLE_TEMPORARY_TUTORIAL_ACTIVE: {
      return {
        ...state,
        isTemporaryTutorialActive: !state.isTemporaryTutorialActive,
      };
    }

    default:
      return state;
  }
}

export const getActiveTutorialStep = player => {
  if (player.tutorialStepIndex === null) {
    return null;
  }
  return player.tutorialSteps[player.tutorialStepIndex];
};

// Using function just for hoisting so these don't clutter top of initial state, or unless we add a constants file.
function getTutorialSteps() {
  return [
    {
      targetElemId: 'navbar',
      text:
        'This area contains information about your team, your role, the current month and total points accumulated for your team. You may also launch this tour of the interface from here at any time.',
    },
    {
      targetElemId: 'conversations',
      text:
        'Use the inbox to read important messages about your employees. You can expect to have new messages to read each month.',
    },
    {
      targetElemId: 'employee0',
      text:
        "As a manager, you have to manage 3 employees. Here you see each employee's name to get information about each employee’s background, skills, level of engagement, and special characteristics. ",
    },
    // TODO: these were  demos for prompting an action to continue.
    // {
    //   targetElemId: 'trainButton0',
    //   positionOverride: 'right',
    //   text: "DEMO: additionally there are management action buttons for assigning them to projects, and motivating or coaching employees.",
    //   actionPrompt: "Click on Train Employee and select Functional skills."
    // },
    // {
    //   targetElemId: 'employee0',
    //   positionOverride: 'bottom',
    //   text: "DEMO: here is a description of the result of having trained them."
    // }
    // TODO: this one breaks. I can't get the token container z-index to allow it to sit on top of overlay. Only changing z-index on entire SideBar works so far.
    // {
    //   targetElemId: 'tokenContainer',
    //   text: "You can use time tokens to coach or recognize employees or contribute your time to projects. Once assigned, time tokens cannot be reclaimed."
    // },
    {
      targetElemId: 'assignButton0',
      text: 'Click this button to assign to project.',
      positionOverride: 'right',
    },
    {
      targetElemId: 'assignButton0',
      text:
        'After assigning, you will be able to unassign them up until project is submitted.',
      positionOverride: 'bottom',
    },
    {
      targetElemId: 'allProjectsTab',
      text: 'The all tasks view shows all tasks available in a quarter.',
      positionOverride: 'left',
    },
    {
      targetElemId: 'activeProjectsTab',
      text:
        'Active projects include any that have been activated by you or your teammate.',
    },
    // TODO: nowhere to place this one in current model
    // {
    //   targetElemId: null,
    //   text:  "After selecting a task, this area will show the required skills to complete a task, the skills applied to the task, and the task's deadline and points."
    // },
    {
      targetElemId: 'completedProjectsTab',
      text:
        'Once the team has submitted a completed task, it will appear in the completed menu.',
    },
  ];
}
