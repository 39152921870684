import _ from 'lodash';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';
import useConfig from '../../../hooks/use-config';

function MarketView(props) {
  const { tFallback } = useConfig();
  const { marketViewData } = props;

  const groupedData = _.groupBy(marketViewData, 'companyName');
  const reportData = {
    labels: [
      `${tFallback('LABEL_MONTH', 'Month')} 0`,
      `${tFallback('LABEL_MONTH', 'Month')} 1`,
      `${tFallback('LABEL_MONTH', 'Month')} 2`,
      `${tFallback('LABEL_MONTH', 'Month')} 3`,
      `${tFallback('LABEL_MONTH', 'Month')} 4`,
      `${tFallback('LABEL_MONTH', 'Month')} 5`,
      `${tFallback('LABEL_MONTH', 'Month')} 6`,
    ],
    datasets: _.map(_.keys(groupedData), (companyName, i) => ({
      label: companyName,
      fill: true,
      backgroundColor: 'transparent',
      borderColor: props.reportTeamColors[i],
      data: [0, ..._.map(groupedData[companyName], 'score')],
    })),
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: 'rgba(0,0,0,0.05)',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            stepSize: 500,
          },
          display: true,
          borderDash: [5, 5],
          gridLines: {
            color: 'rgba(0,0,0,0)',
            fontColor: '#fff',
          },
        },
      ],
    },
  };

  return (
    <React.Fragment>
      <div style={{ height: '300px' }}>
        <Line data={reportData} options={options} />
      </div>
      <Table striped>
        <thead>
          <tr>
            <th>{tFallback('TEAM_RECOGNITION_DISPLAY', 'Team')}</th>
            <th className="text-right" width="5%">
              {tFallback('LABEL_RANK', 'Rank')}
            </th>
            <th className="text-right" width="10%">
              {tFallback('SCORE', 'Score')}
            </th>
          </tr>
        </thead>
        <tbody>
          {reportData.datasets.map(({ data, label }) => (
            <tr key={label}>
              <td>{label}</td>
              <td className="text-right">
                {getScoreRank(reportData.datasets, _.sum(data), tFallback)}
              </td>
              <td className="text-right">{_.sum(data)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
}

export default connect((state) => ({
  marketViewData: state.reports.historicalCompanyScores,
  reportTeamColors: state.theme.reportTeamColors,
}))(MarketView);

function getScoreRank(allTeamsData, targetScore, tFallback) {
  const allScoresRanked = _(allTeamsData)
    .map((teamData) => _.sum(teamData.data))
    .uniq()
    .sortBy((val) => val) // Insane but default .sort() treats ints like strings.
    .reverse()
    .value();

  const numericRank = allScoresRanked.indexOf(targetScore) + 1;

  return numericRank === 1
      ? tFallback('LABEL_RANK_FIRST','1st')
      : numericRank === 2
          ? tFallback('LABEL_RANK_SECOND','2nd')
          : numericRank === 3
              ? tFallback('LABEL_RANK_THIRD','3rd')
              : numericRank;
}
