import React, { createContext, useContext, useState, useEffect } from 'react';
import { getDictionary } from '../services/apiService';

// Create the context
const DictionaryContext = createContext();

// Provider component
export const DictionaryProvider = ({ children }) => {
    const [dictionary, setDictionary] = useState([]);

    useEffect(() => {
        async function loadDictionary() {
            const res = await getDictionary();
            setDictionary(res.dictionary);
        }

        loadDictionary();
    }, []);

    return (
        <DictionaryContext.Provider value={{ dictionary }}>
            {children}
        </DictionaryContext.Provider>
    );
};

// Hook to use the dictionary in components
export const useDictionary = () => {
    const context = useContext(DictionaryContext);
    if (!context) {
        throw new Error('useDictionary must be used within a DictionaryProvider');
    }
    return context;
};
