import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Popover, PopoverBody, Row } from 'reactstrap';

import { ArrowLeft, ArrowRight, Circle, X } from 'react-feather';

import styled from 'styled-components';

import {
  NEXT_TUTORIAL_STEP,
  PREVIOUS_TUTORIAL_STEP,
} from '../redux/redux-constants';
import { endTutorial } from '../services/apiService';

import store from '../redux/store';

// TODO: use useRef insteads
function handleKeyDown({ key }) {
  if (key === 'ArrowLeft') {
    store.dispatch({ type: PREVIOUS_TUTORIAL_STEP });
  } else if (key === 'ArrowRight') {
    store.dispatch({ type: NEXT_TUTORIAL_STEP });
  } else if (key === 'Escape') {
    endTutorial();
  }
}

const Tutorial = props => {
  const { tutorialStepIndex, tutorialSteps, theme, isTutorialReady } = props;

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => document.removeEventListener('keydown', handleKeyDown, false);
  }, []);

  if (!isTutorialReady) {
    // TODO: return loading indiciator
    return null;
  }

  console.log(
    'tutorialStepIndex',
    tutorialStepIndex,
    tutorialSteps[tutorialStepIndex]
  );

  // TODO: consider showing loading indicator while we are waiting on tutorial session to spin up.
  // TODO: consider adding reset button to avoid them being stuck from having used all their tokens.

  return tutorialSteps.map(
    ({ text, targetElemId, positionOverride, actionPrompt }, i) => (
      <Popover
        key={i}
        placement={positionOverride || 'auto'}
        isOpen={i === tutorialStepIndex}
        target={targetElemId || 'body'}
        toggle={() => ({})}
      >
        {/*<PopoverHeader>*/}
        {/*  <PopoverTitle>MC Tutorial</PopoverTitle>*/}
        {/*</PopoverHeader>*/}
        <StyledBody>
          <CloseIcon onClick={endTutorial} />
          <div>{text}</div>
          {actionPrompt && <ActionPromptText>{actionPrompt}</ActionPromptText>}
          <Footer
            hasActionPrompt={!!actionPrompt}
            className="d-flex flex-row justify-content-between align-items-center"
          >
            <ArrowLeft
              onClick={() =>
                tutorialStepIndex !== 0 &&
                props.dispatch({ type: PREVIOUS_TUTORIAL_STEP })
              }
            />
            {tutorialSteps.map((step, i) => (
              <Circle
                key={i}
                size="10"
                color={i <= tutorialStepIndex ? theme.primary : '#333'}
                fill={i <= tutorialStepIndex ? theme.primary : 'transparent'}
              />
            ))}
            {/* TODO: disable or remove arrow button when they have an action prompt. */}
            <ArrowRight
              onClick={() =>
                tutorialStepIndex < tutorialSteps.length - 1 &&
                props.dispatch({ type: NEXT_TUTORIAL_STEP })
              }
            />
          </Footer>
        </StyledBody>
      </Popover>
    )
  );
};

export default connect(state => ({
  tutorialStepIndex: state.player.tutorialStepIndex,
  tutorialSteps: state.player.tutorialSteps,
  isTutorialReady: state.player.isTutorialReady,
  theme: state.theme.currentTheme,
}))(Tutorial);

const Footer = styled(({ hasActionPrompt, ...rest }) => <Row {...rest} />)`
  margin: ${p => (p.hasActionPrompt ? '12px 0 0 0' : '24px 0 0 0')} !important;
`;

const StyledBody = styled(PopoverBody)`
  position: relative;
  padding: 24px !important;
  font-size: 13px !important;
  color: '#2d2323';
`;

const CloseIcon = styled(X)`
  position: absolute;
  cursor: pointer;
  top: 5px;
  right: 5px;
`;

const ActionPromptText = styled.div`
  margin-top: 12px;
  font-weight: 600;
`;
