// TODO: real cleanup needed. There are three different types of actions:
//   1. Client-only local actions
//   2. Server socket actions with no payload informing the user to pull data from server
//   3. Client generated actions that set data based on hitting a server endpoint
//   Without distinguishing, it's hard to tell that only type 2 can be actions from a different session that should be ignored.
//   Further, for simplicity, the facilitator should be changed to use the same GET broadcast with a corresponding SET.
//   Also note, all socket messages coming from the server have a sessionId on the object at the same level as payload.

// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_STICKY_TOGGLE = "SIDEBAR_STICKY_TOGGLE";
export const SIDEBAR_STICKY_ENABLE = "SIDEBAR_STICKY_ENABLE";
export const SIDEBAR_STICKY_DISABLE = "SIDEBAR_STICKY_DISABLE";

// Layout
export const LAYOUT_BOXED_TOGGLE = "LAYOUT_BOXED_TOGGLE";
export const LAYOUT_BOXED_ENABLE = "LAYOUT_BOXED_ENABLE";
export const LAYOUT_BOXED_DISABLE = "LAYOUT_BOXED_DISABLE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

// State (top-level)
export const SET_GAME_STATE = "SET_GAME_STATE";
export const SET_REPORT_STATE = "SET_REPORT_STATE";
export const SET_CONVERSATION_STATE = "SET_CONVERSATION_STATE";

export const SET_FACILITATOR_STATE = "SET_FACILITATOR_STATE";

// Conversations
export const SET_VIDEO = "SET_VIDEO";
export const SET_CURRENT_MILESTONE = 'SET_CURRENT_MILESTONE';
export const SET_CLIP_ENDED = 'SET_CLIP_ENDED';
export const SET_VIDEO_POINTS = 'SET_VIDEO_POINTS';

// Notifications
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";

// Player
export const LAUNCH_TUTORIAL = "LAUNCH_TUTORIAL";
export const SET_TUTORIAL_READY = "SET_TUTORIAL_READY";
export const NEXT_TUTORIAL_STEP = "NEXT_TUTORIAL_STEP";
export const PREVIOUS_TUTORIAL_STEP = "PREVIOUS_TUTORIAL_STEP";
export const END_TUTORIAL = "END_TUTORIAL";
export const SET_TEAMMATE_VIEW = "SET_TEAMMATE_VIEW";
export const TOGGLE_TEMPORARY_TUTORIAL_ACTIVE = "TOGGLE_TEMPORARY_TUTORIAL_ACTIVE";

// Socket IO
//---> Conversation Controls
export const SOCKET_SET_VIDEO = "SOCKET_SET_VIDEO";
export const SOCKET_SET_CURRENT_MILESTONE = "SOCKET_SET_CURRENT_MILESTONE";
export const SOCKET_SET_VIDEO_POINTS = "SOCKET_SET_VIDEO_POINTS";

//---> Conversation View
export const SOCKET_SET_CLIP_ENDED = "SOCKET_SET_CLIP_ENDED";

// Socket action notification
export const GET_GAME_STATE  = "GET_GAME_STATE";
// todo: probably to be removed
export const GET_IS_BLACKOUT_SCREEN_VISIBLE  = "GET_IS_BLACKOUT_SCREEN_VISIBLE";
export const GET_CONVERSATION_STATE = "GET_CONVERSATION_STATE";
export const CLOSE_TUTORIAL_SESSIONS = "CLOSE_TUTORIAL_SESSIONS";
export const SET_TIMER_UPDATE = "SET_TIMER_UPDATE";

export const RULES_APPLIED = "RULES_APPLIED";
export const TRAINING_RESULT = "TRAINING_RESULT";

export const GET_FACILITATOR_STATE = "GET_FACILITATOR_STATE";
export const SET_FACILITATOR_REPORT_STATE = "SET_FACILITATOR_REPORT_STATE";
export const SET_FACILITATOR_LATEST_REPORT_STATE = "SET_FACILITATOR_LATEST_REPORT_STATE";
export const SET_FACILITATOR_LATEST_DASHBOARD_REPORT_STATE = "SET_FACILITATOR_LATEST_DASHBOARD_REPORT_STATE";
export const SET_FACILITATOR_SESSION_ID = "SET_FACILITATOR_SESSION_ID";
export const SET_FACILITATOR_ALL_SESSION_IDS = "SET_FACILITATOR_ALL_SESSION_IDS";
export const SET_FACILITATOR_TIMER_UPDATE = "SET_FACILITATOR_TIMER_UPDATE";
export const SET_FACILITATOR_SECONDS_REMAINING = "SET_FACILITATOR_SECONDS_REMAINING";

export const ROLLBACK_ROUND_COMPLETE = "ROLLBACK_ROUND_COMPLETE"; // Player and facilitator message

// TODO: rename to something more explicit. Is this only ever to be used for facilitator?
export const UPDATE_FILTERED_TEAMS = "UPDATE_FILTERED_TEAMS";

// Training progress/results
export const START_TRAINING_EMPLOYEE = "START_TRAINING_EMPLOYEE";
export const END_TRAINING_EMPLOYEE = "END_TRAINING_EMPLOYEE";
export const SET_TRAINING_RESULT_POINTS = "SET_TRAINING_RESULT_POINTS";

export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';

