import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Badge, Collapse, Button } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Sliders } from 'react-feather';
import routes from '../routes/index';
import { facilitator as facilitatorRoutes } from '../routes/index';
import logo from '../assets/img/logo.png';
import logoMark from '../assets/img/logo-mark.png';
import _ from 'lodash';
import useConfig from '../hooks/use-config';

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    sidebarIsOpen,
    path,
  }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        _.includes(location.pathname, path.replace('*', ''))
        ? 'active'
        : '';
    };

    return (
      <li className={'sidebar-item ' + getSidebarItemClass(path)}>
        <span
          data-toggle={sidebarIsOpen && 'collapse'}
          className={'sidebar-link ' + (!isOpen ? 'collapsed' : '')}
          onClick={onClick}
          aria-expanded={isOpen ? 'true' : 'false'}
        >
          {sidebarIsOpen && Icon ? (
            <Icon size={18} className="align-middle mr-3" />
          ) : null}
          {!sidebarIsOpen && Icon ? (
            <Icon size={24} className="align-middle ml-1" />
          ) : null}
          {sidebarIsOpen && <span className="align-middle">{name}</span>}
          {badgeColor && badgeText ? (
            <Badge pill color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={'sidebar-dropdown list-unstyled'}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    location,
    path,
    to,
    sidebarIsOpen,
  }) => {
    const getSidebarItemClass = () => {
      const includesRouteParam = _.includes(path, ':');
      return location.pathname === path ||
        (includesRouteParam &&
          _.includes(location.pathname, path.split(':')[0]))
        ? 'active'
        : '';
    };

    const id = _.camelCase(name);

    return (
      <StyledNavLinkWrapper
        className={'sidebar-item ' + getSidebarItemClass(path)}
        id={id}
      >
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {sidebarIsOpen && Icon ? <Icon size={18} className="mr-3" /> : null}
          {!sidebarIsOpen && Icon ? <Icon size={24} className="ml-1" /> : null}
          {sidebarIsOpen && name}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </StyledNavLinkWrapper>
    );
  }
);

function Sidebar(props) {
  const { tFallback } = useConfig();
  const [routeStatus, setRouteStatus] = useState({});
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      const pathName = props.location.pathname;

      routes.forEach((route, index) => {
        const isActive =
          pathName.indexOf(route.path) === 0 ||
          _.includes(pathName, route.path.replace('*', ''));
        const isOpen = route.open;
        const isHome = route.containsHome && pathName === '/';

        setRouteStatus((routeStatus) =>
          _.assign({}, routeStatus, { [index]: isActive || isOpen || isHome })
        );
      });

      setInitialized(true);
    }
  }, [setRouteStatus, initialized, props.location.pathname]);

  const toggle = (index) => {
    setRouteStatus(_.assign({}, routeStatus, { [index]: !routeStatus[index] }));
  };

  const { sidebar } = props;
  const isSidebarOpen = sidebar.isOpen;

  return (
    <div
      className={
        'sidebar' +
        (!isSidebarOpen ? ' toggled' : '') +
        (sidebar.isSticky ? ' sidebar-sticky' : '')
      }
    >
      <div className="sidebar-content">
        <PerfectScrollbar>
          {!isSidebarOpen && (
            <a className="sidebar-brand-mark" href="/">
              <img src={logoMark} alt="Management Challenge" width="50px" />
            </a>
          )}
          {isSidebarOpen && (
            <a className="sidebar-brand" href="/">
              <img src={logo} alt="Management Challenge" width="100%" />
            </a>
          )}

          <ul className="sidebar-nav mt-3">
            {facilitatorRoutes.map((category, index) => {
              return (
                <React.Fragment key={index}>
                  {category.header ? (
                    <li className="sidebar-header">{category.header}</li>
                  ) : null}

                  {category.children && isSidebarOpen ? (
                    <SidebarCategory
                      name={
                        category.nameDictionaryKey
                          ? tFallback(category.nameDictionaryKey, category.name)
                          : category.name
                      }
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                      icon={category.icon}
                      path={category.path}
                      to={category.to || category.path}
                      isOpen={routeStatus[index]}
                      onClick={() => toggle(index)}
                      sidebarIsOpen={isSidebarOpen}
                    >
                      {category.children.map((route, index) => (
                        <SidebarItem
                          key={index}
                          name={
                            route.nameDictionaryKey
                              ? tFallback(route.nameDictionaryKey, route.name)
                              : route.name
                          }
                          to={route.to || route.path}
                          path={route.path}
                          badgeColor={route.badgeColor}
                          badgeText={route.badgeText}
                          desc={
                            route.descDictionaryKey
                              ? tFallback(route.descDictionaryKey, route.desc)
                              : route.desc
                          }
                          sidebarIsOpen={isSidebarOpen}
                        />
                      ))}
                    </SidebarCategory>
                  ) : (
                    <SidebarItem
                      name={
                        category.nameDictionaryKey
                          ? tFallback(category.nameDictionaryKey, category.name)
                          : category.name
                      }
                      to={category.to || category.path}
                      path={category.path}
                      icon={category.icon}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                      sidebarIsOpen={isSidebarOpen}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </ul>
          <div className="sidebar-bottom d-none d-lg-block">
            <div className="media">
              <div className="d-flex flex-column align-items-center">
                <Sliders
                  size={18}
                  className="text-muted"
                  style={{ marginLeft: 22, marginRight: 32 }}
                />
                <Button
                  onClick={() => props.history.push('/sign-in')}
                  outline
                  color="primary"
                  size="sm"
                  className="position-absolute d-flex align-items-center"
                  style={{
                    bottom: '13px',
                    left: '12px',
                    fontSize: '11px',
                    padding: '2px 4px 2px 4px',
                    lineHeight: '1',
                  }}
                >
                  {tFallback('LABEL_LOGOUT', 'Logout')}
                </Button>
              </div>
              <div className="media-body">
                <div className="d-flex flex-column">
                  <h4 className="mb-0">
                    {tFallback('LABEL_FACILITATOR', 'Facilitator')}
                  </h4>
                </div>
                <div className="d-flex justify-content-between flex-fill">
                  <span>
                    <span className="text-muted">
                      {tFallback('LABEL_SESSION', 'Session')}{' '}
                    </span>
                    {localStorage.getItem('facilitatorSessionId')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
}

export default withRouter(
  connect((store) => ({
    sidebar: store.sidebar,
  }))(Sidebar)
);

const StyledNavLinkWrapper = styled(({ isTutorialTarget, ...rest }) => (
  <li {...rest} />
))`
  z-index: ${(p) => (p.isTutorialTarget ? '1001' : 'unset')};
  position: ${(p) => (p.isTutorialTarget ? 'relative' : 'unset')};
`;
